body {
    margin: 0;
    padding: 0;
    background: transparent url('/images/body_bg.jpg') repeat top center / cover;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

* {
    box-sizing: border-box;
}

a {
    color: #a35ea5;
}

#root {
    padding: 0 30px;
}

img {
    max-width: 100%;
    height: auto;
}

.center_text {
    text-align: center;
}

h1 {
    margin: 60px auto 25px auto;
    font-family: 'Dosis', sans-serif;
    font-size: 45px;
    width: 100%;
    line-height: 45px;
}

.green_headline {
    color: #40beb7;
    max-width: 330px;
}

.green_headline_long {
    max-width: 650px;
}

.blue_headline {
    color: #50b6df;
    max-width: 560px;
}

.pink_headline {
    color: #ea4f9b;
    max-width: 500px;
}

h1 img {
    max-width: 250px;
}


/*Header*/

#main_menu {
    padding: 0;
    margin: 0;
    max-width: 800px;
    width: 100%;
    margin: 25px auto;
    text-align: center;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    position: relative;
    z-index: 100;
}

#main_menu a.active {
    position: relative;
}

#main_menu a.active:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    margin: auto;
    height: 2px;
    width: 90%;
    background: #e9509c;
}

.sticky {
    z-index: 100;
    background: rgba(255, 255, 255, 0.9);
}

.sticky #main_menu {
    margin: 10px auto;
}

.sticky img#logo {
    max-width: 55px;
}

#main_menu li {
    display: inline-block;
    list-style: none;
    vertical-align: middle;
    margin: 0 5px;
    padding: 0 5px;
}

#main_menu a {
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

#logo {
    max-width: 70px;
}


/**Tabs**/

#tabs {
    max-width: 650px;
    margin: 0 auto;
}

.custom_tabs_container {
    position: relative;
}

#absolute_girl {
    position: absolute;
    top: -90px;
    z-index: -1;
    right: 30px;
}

#disclaimer,
.disclaimer {
    font-weight: 400;
    color: #a35ea5;
    font-size: 12px;
    text-align: center;
}

.water_disclaimer {
    max-width: 700px;
    margin: auto;
}

.rmc-tabs-tab-bar-tab-active {
    color: #fff;
}

#tabs .rmc-tabs-tab-bar-tab p {
    font-size: 18px;
}

.rmc-tabs-tab-bar-tab-active {
    background: #4dabd1;
}

.i_ate_custom_tab svg {
    font-size: 55px;
    stroke-width: 15;
}

.i_ate_custom_tab {
    font-weight: 600;
    padding: 15px 0;
}

.rmc-tabs {
    background: #e9e9e9;
    /* Safari 3-4, iOS 1-3.2, Android 1.6- */
    /* Firefox 1-3.6 */
    /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
    border-radius: 15px;
}

#tabs .rmc-tabs-tab-bar-left .rmc-tabs-tab-bar-tab,
#tabs .rmc-tabs-tab-bar-right .rmc-tabs-tab-bar-tab {
    padding: 0 30px;
    cursor: pointer;
}

.rmc-tabs-tab-bar-content .rmc-tabs-tab-bar-tab-active:first-child {
    background: #e84f9a;
}

.rmc-tabs-content-wrap {
    padding: 10px 10px 10px 0;
}

.calc_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0px;
    border-bottom: 2px solid #fff;
}

.calc_row:last-child {
    border: none;
}

.calc_label {
    width: 25%;
    padding: 5px 10px;
    text-align: right;
    color: #7c7c7c;
    font-weight: 600;
    font-size: 20px;
}

.inactive_stars {
    position: relative;
    opacity: 0.3;
}

.inactive_stars .rating_stars span {
    color: gray !important;
}

.inactive_stars_overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 100;
}

.active_stars {
    position: relative;
}


/* Chrome, Safari, AND NOW ALSO the Edge Browser and Firefox */

@media and (-webkit-min-device-pixel-ratio:0) {
    .calc_field .react-numeric-input input {
        font-size: 22px !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .calc_field .react-numeric-input input {
        font-size: 22px !important;
    }
}


/* Chrome 29+ */

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .calc_field .react-numeric-input input {
        font-size: 22px !important;
    }
}


/* Chrome 22-28 */

@media screen and(-webkit-min-device-pixel-ratio:0) {
    .calc_field .react-numeric-input input {
        -chrome-: only(;
        font-size: 22px !important;
        );
    }
}

.calc_field {
    width: 75%;
    padding: 5px 10px;
}

.calc_field .rangeslider-horizontal .rangeslider__handle {
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: none;
}

.calc_field .rangeslider-horizontal {
    height: 8px;
}

.girl_tab .rangeslider-horizontal .rangeslider__fill {
    background-color: #e84f9a;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

.boy_tab .rangeslider-horizontal .rangeslider__fill {
    background-color: #4dabd1;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

.girl_tab .rangeslider-horizontal .rangeslider__fill:hover {
    background-color: #e02e84;
}

.boy_tab .rangeslider-horizontal .rangeslider__fill:hover {
    background-color: #0c99d1;
}

.calc_field .rangeslider-horizontal .rangeslider__handle::after {
    height: 0;
    width: 0;
}

.girl_tab .rangeslider .rangeslider__handle {
    background: #06b7a7;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

.boy_tab .rangeslider .rangeslider__handle {
    background: #a35ea5;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

.girl_tab .rangeslider .rangeslider__handle:hover {
    background: #078d82;
}

.boy_tab .rangeslider .rangeslider__handle:hover {
    background: #a63baa;
}

.calc_sub_field_slider {
    padding-right: 20px;
}

.calc_field_age {
    display: flex;
    align-items: center;
}

.calc_sub_field_age {
    display: flex;
    align-items: center;
    border-radius: 25px;
    background: #a35ea5;
}

.age,
.age_picture {
    padding: #fff;
    padding: 3px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.age {
    width: 40%;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.age_picture {
    width: 60%;
    -webkit-border-top-right-radius: 25px;
    -webkit-border-bottom-right-radius: 25px;
    -moz-border-top-right-radius: 25px;
    -moz-border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.calc_sub_field_slider {
    width: 40%;
}

.calc_sub_field_age {
    width: 60%;
}

.react-numeric-input,
.react-numeric-input input {
    display: inline-block;
    width: 100%;
}

.react-numeric-input {
    padding: 10px;
    background: #fff;
}

.react-numeric-input input {
    border: none !important;
}

.result_container {
    display: flex;
    width: 100%;
    border-radius: 25px;
    margin: 10px 0 20px 0;
}

.result_col {
    width: 50%;
}

.result_left {
    -webkit-border-top-left-radius: 15px;
    -webkit-border-bottom-left-radius: 15px;
    -moz-border-top-left-radius: 15px;
    -moz-border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 10px;
    background: #a35ea5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result_left span {
    display: inline-block;
    width: 100%;
    color: #fff;
}

.advise_text {
    width: 80%;
    text-align: right;
    padding-right: 10px;
}

.advise_text span {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
}

.bottles_to_drink {
    width: 20%;
}

.result_right {
    -webkit-border-top-right-radius: 15px;
    -webkit-border-bottom-right-radius: 15px;
    -moz-border-top-right-radius: 15px;
    -moz-border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px;
    background: #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bottles_label {
    font-size: 12px;
}

.bottles_count {
    font-family: 'Dosis', sans-serif;
    font-size: 55px;
    line-height: 55px;
}

.result_right ul {
    margin: 0;
    padding: 0;
}

.result_right li {
    list-style: none;
    margin: 0 3px;
    display: inline-block;
}

.result_right img {
    max-width: 18px;
}


/**Static media**/

#static_bottles_container {
    width: 100%;
}

#static_bottles {
    max-width: 700px;
}

.react-numeric-input b {
    width: 40px !important;
    border-radius: 40px !important;
}

.react-numeric-input b i {
    background: #fff !important;
}

.girl_tab .react-numeric-input input,
.girl_tab input {
    color: #e84f9a;
}

.boy_tab .react-numeric-input input,
.boy_tab input {
    color: #4dabd1;
}

.boy_tab input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #4dabd1;
    opacity: 0.6;
}

.boy_tab input::-moz-placeholder {
    /* Firefox 19+ */
    color: #4dabd1;
    opacity: 0.6;
}

.boy_tab input:-ms-input-placeholder {
    /* IE 10+ */
    color: #4dabd1;
    opacity: 0.6;
}

.boy_tab input:-moz-placeholder {
    /* Firefox 18- */
    color: #4dabd1;
    opacity: 0.6;
}

.girl_tab input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #e84f9a;
    opacity: 0.6;
}

.girl_tab input::-moz-placeholder {
    /* Firefox 19+ */
    color: #e84f9a;
    opacity: 0.6;
}

.girl_tab input:-ms-input-placeholder {
    /* IE 10+ */
    color: #e84f9a;
    opacity: 0.6;
}

.girl_tab input:-moz-placeholder {
    /* Firefox 18- */
    color: #e84f9a;
    opacity: 0.6;
}

.girl_tab .react-numeric-input input,
.boy_tab .react-numeric-input input {
    padding: 0 !important;
    background: transparent;
    text-indent: -20px;
    font-weight: 600 !important;
}

.girl_tab .react-numeric-input {
    background: #fff url('/images/kg_girl.png') no-repeat top 14px right 44% / 25px 20px;
}

.boy_tab .react-numeric-input {
    background: #fff url('/images/kg_boy.png') no-repeat top 14px right 44% / 25px 20px;
}

.girl_tab .react-numeric-input b {
    background: #e84f9a !important;
    cursor: pointer !important;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

.girl_tab .react-numeric-input b:hover {
    background: #d33782 !important;
}

.boy_tab .react-numeric-input b {
    background: #4dabd1 !important;
    cursor: pointer !important;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

.boy_tab .react-numeric-input b:hover {
    background: #0f9bd3 !important;
}

.calc_field input[type='text'] {
    padding: 10px;
    border: none;
    border-radius: 25px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 22px;
}

.rating_stars {
    display: flex;
    justify-content: center;
}

.rating_stars span {
    line-height: 1em;
    -webkit-transition: ease-in-out 350ms;
    transition: ease-in-out 350ms;
}

#rating_label {
    text-align: center;
    font-style: italic;
    padding: 0;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
}

#rating_label li {
    list-style: none;
}


/**Footer**/

.footer {
    padding: 100px 0 30px 0;
}

#address {
    color: #6a5f5a;
    font-weight: bold;
    margin-bottom: 50px;
}

#social_media {
    margin: 0;
    padding: 0;
}

#social_media li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
}

#social_media img {
    max-width: 40px;
}

.youtube_container {
    max-width: 800px;
    margin: 0 auto;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/**Mobile devices**/

@media(max-width: 767px) {
    #static_bottles {
        max-width: 100%;
    }
}

@media(max-width: 640px) {
    .i_ate_custom_tab svg {
        font-size: 35px;
    }
    #tabs .rmc-tabs-tab-bar-left .rmc-tabs-tab-bar-tab,
    #tabs .rmc-tabs-tab-bar-right .rmc-tabs-tab-bar-tab {
        padding: 0 20px;
    }
    .calc_label,
    .calc_field {
        width: 100%;
        text-align: center;
    }
    #tabs .rmc-tabs-tab-bar-tab p {
        margin: 5px 0;
    }
    .i_ate_custom_tab,
    .rmc-tabs-content-wrap {
        padding: 0;
    }
    .calc_row {
        padding: 10px 0;
    }
    #absolute_girl {
        display: none;
    }
    h1 {
        font-size: 30px;
        margin: 30px auto 15px auto;
    }
    #root {
        padding: 0 15px;
    }
    .result_container {
        display: inline-block;
        width: 100%;
    }
    .result_col {
        width: 100%;
    }
    #logo_wrapper {
        padding: 15px 0 10px 0;
        text-align: center;
    }
    #main_menu {
        margin: 15px auto;
    }
    .result_left {
        -moz-border-bottom-left-radius: 0;
        border-bottom-left-radius: 0;
        -moz-border-top-right-radius: 15px;
        border-top-right-radius: 15px;
    }
    .result_right {
        -moz-border-top-right-radius: 0;
        border-top-right-radius: 0;
        -moz-border-bottom-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
}

@media(max-width: 480px) {
    h1 img {
        max-width: 100%;
    }
    .girl_tab .react-numeric-input {
        background: #fff url('/images/kg_girl.png') no-repeat top 14px right 43% / 25px 20px;
    }
    .boy_tab .react-numeric-input {
        background: #fff url('/images/kg_boy.png') no-repeat top 14px right 43% / 25px 20px;
    }
}


/**UPDATE**/

.cookie_banner {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    z-index: 10000;
}

#cookie_agree_btn {
    background: #36ade3;
    border: 2px solid #36ade3;
    color: #fff;
    display: inline-block;
    padding: 3px 5px;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    max-width: 200px;
    width: 100%;
    margin: 0 5px;
}

#cookie_details_btn {
    background: #ee375b;
    border: 2px solid #ee375b;
    color: #fff;
    display: inline-block;
    padding: 3px 5px;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    max-width: 200px;
    width: 100%;
    margin: 0 5px;
}

button.see_answer {
    background: #06b7a7;
    margin: 0 auto;
    border-radius: 25px;
    text-transform: none;
    text-transform: initial;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    max-width: 200px;
    width: 100%;
    padding: 10px 5px;
}

button.see_answer:hover {
    background: #1cc0b2;
}

.see_answer_button_container {
    text-align: center;
}

input#chbx_boy:checked,
.boy_check_active svg {
    color: #4dabd1;
}

.client_validation_error {
    color: #f00;
    font-weight: 600;
    text-align: center;
    padding: 0 5px;
}

.wrapper {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.cookie_header {
    text-align: center;
    padding-top: 15px;
}

.cookie_header h1 {
    margin-top: 20px;
    font-size: 30px;
    max-width: 500px;
    line-height: 1em;
}

.cookie_text p {
    display: inline-block;
    width: 100%;
}

label a {
    color: #a35ea5;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid #a35ea5;
}

label[for='chbx_girl'] {
    font-weight: 500;
    color: #e84f9a;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1em;
}

label[for='chbx_boy'] {
    font-weight: 500;
    color: #4dabd1;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1em;
}

.cookie_text_container {
    padding: 10px 0 40px 0;
}

.cookie_text_container ol {
    padding-left: 30px;
}

.ytb_container_with_border {
    border: 4px dashed;
    border-radius: 25px;
    padding: 20px;
}

.ytb_blue_border {
    border-color: #00a6ff;
}

.ytb_red_border {
    border-color: #e84e9a;
}

.ytb_green_border {
    border-color: #8ac441;
}
